import React from 'react';
import { Chip, Box } from '@mui/material';
import { useFieldValue } from 'react-admin';

const TagChipField = ( props ) => {

	const value = useFieldValue( props );
    // Split the string into an array
    const items = value ? value.trim().split(',').filter( t => t ).map(item => item.trim()) : [];

    return (
        <Box>
            {items.map((item, index) => (
                <Chip key={index} label={item} style={{ margin: '2px' }} />
            ))}
        </Box>
    );
};

export default TagChipField;
