import { ReferenceInput, DateInput, Edit, ReferenceArrayInput, AutocompleteArrayInput, TextInput, SelectInput, required, ArrayInput, SimpleFormIterator, ReferenceManyField, SimpleForm } from 'react-admin';
import { voiceoverOptionText, expertOptionText, StatusChoices } from './meta';


export const edit = () => (
	<Edit>
		<SimpleForm>
			<TextInput source="name" validate={required()} />
			<DateInput source="start_date" fullWidth={false} />
			<SelectInput source="status" choices={StatusChoices} validate={required()} fullWidth={false} />
			<TextInput source="instructions" multiline validate={required()} />

			<ReferenceInput source="pre_voiceover_id" reference="voiceovers" label="Pre-experience Voiceover">
				<SelectInput label="Pre-Experience Voiceover/Text" optionText={ voiceoverOptionText }/>
			</ReferenceInput>

			<TextInput source="question1" multiline rows={4} />
			<TextInput source="question2" multiline rows={4} />
			<TextInput source="question3" multiline rows={4} />
			<TextInput source="question4" multiline rows={4} />

			<ArrayInput source="links">
				<SimpleFormIterator inline>
					<TextInput source="title" />
					<TextInput source="url" />
				</SimpleFormIterator>
			</ArrayInput>

			<ReferenceArrayInput source="experts" reference="experts">
				<AutocompleteArrayInput label="Experts" optionText={ expertOptionText } />
			</ReferenceArrayInput>
		</SimpleForm>
	</Edit>
);

export default edit;